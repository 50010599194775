import clsx from 'clsx';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTable } from 'react-table';
import { DisabledResizer } from './components/DisabledResizer';
import { DisabledSorter } from './components/DisabledSorter';
import { useChildClassTableStyles } from './styles';
import { ChildClassTableProps } from './types';
import { useRecordPropFieldsMap, useRecordFieldsMap } from '../../../../hooks';
import { ChildClassColumns } from 'components/formBuilder/formBuilder/types';
import {
  DEFAULT_COLUMNS_WIDTHS,
  ACTIONS_COLUMN_WIDTH,
} from 'components/Table/Table.consts';
import { FIELD_PREFIX } from 'utils/consts';
import { isFieldSortable } from '../../../../utils';
import { TableDensity } from 'components/Table/enums';
import { useSelector } from 'react-redux';
import { getTableDensity } from 'store/selectors/preferencesSelectors';
import { selectChildClassById } from 'store/selectors/childClassesSelectors';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';

const ChildClassTable = ({ passThrough }: ChildClassTableProps) => {
  const objectRecrdPropFields = useRecordPropFieldsMap();
  const density = useSelector(getTableDensity) ?? TableDensity.Default;

  const [childClassColumns] = useState<ChildClassColumns>(
    passThrough?.childClassColumns ?? [
      { id: 'id', width: DEFAULT_COLUMNS_WIDTHS.minWidth },
    ]
  );
  const childClassID = passThrough?.childClassID as string;

  const { selectedObjectrecordFields } = useRecordFieldsMap(
    childClassID,
    childClassColumns
  );

  const childClass = useSelector(selectChildClassById(childClassID));
  const totalColsWidth =
    childClassColumns.reduce((acc, col) => acc + col.width, 0) +
    ACTIONS_COLUMN_WIDTH;

  const columns = React.useMemo(
    // in future there will be columns coming from column config. They will be appended here
    () => {
      const getLabel = (fieldId: string) => {
        if (fieldId.startsWith(FIELD_PREFIX)) {
          return selectedObjectrecordFields[fieldId]?.label ?? '';
        } else {
          return objectRecrdPropFields[fieldId]?.label ?? '';
        }
      };
      return childClassColumns.map(col => {
        return {
          Header: getLabel(col.id),
          width: col.width,
          minWidth: col.width,
          maxWidth: col.width,
          accessor: col.id,
        };
      });
    },
    [childClassColumns, objectRecrdPropFields, selectedObjectrecordFields]
  );
  const data: object[] =
    childClass?.multiplicity === ChildClassMultiplicity.ZERO_OR_MORE
      ? [{ ID: '' }, { ID: '' }, { ID: '' }, { ID: '' }, { ID: '' }]
      : [{ ID: '' }]; // mocked data to render 5 rows
  const { getTableProps, getTableBodyProps, headerGroups, rows } = useTable({
    columns,
    data,
  });

  const styles = useChildClassTableStyles({ density });

  return (
    <div {...getTableProps()} className={styles.table}>
      <div>
        <div className={styles.headerRow}>
          {headerGroups.map(headerGroup => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps();

            return (
              <div key={key} className={styles.row} {...rest}>
                {headerGroup.headers.map(column => {
                  const { key, ...rest } = column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.width,
                    },
                  });

                  return (
                    <div
                      key={key}
                      className={clsx([styles.tableCell, styles.tableHeadCell])}
                      {...rest}
                    >
                      {column.render('Header')}
                      {isFieldSortable(column.id, objectRecrdPropFields) ? (
                        <DisabledSorter />
                      ) : null}
                      <DisabledResizer />
                    </div>
                  );
                })}
                <div
                  className={clsx([
                    styles.tableCell,
                    styles.tableHeadCell,
                    styles.actionsHeaderCell,
                  ])}
                >
                  <div
                    className={clsx([
                      styles.flexAlignCenter,
                      styles.actionsHeader,
                    ])}
                  >
                    <span>
                      <FormattedMessage
                        id='formBuilder.childClassComponent.childClassActions'
                        defaultMessage='Actions'
                      />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div {...getTableBodyProps()} className={styles.tableRows}>
          {rows.map(row => {
            return (
              <div className={styles.tableRow} key={row.id}>
                <div style={{ width: `${totalColsWidth}px`, minWidth: '100%' }}>
                  &nbsp;
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChildClassTable;
