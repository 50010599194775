import { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { tryCorrectingFiltersBasedOnApiError } from 'pages/Records/RecordsListing/utils/tryCorrectingFiltersBasedOnApiError';
import { ThunkDispatch } from 'redux-thunk';
import { setFilters, setApplyFilter } from 'store/actions/filtersActions';
import { RootState, RootAction } from 'store/reducers';
import { getCurrentTableFilters } from 'store/selectors/filtersSelectors';
import TablesType from 'utils/Enums/TablesType';
import errorToast from 'utils/functions/errorToast';

export const checkErrorForFilterCorrections = (
  error: AxiosError,
  state: RootState,
  dispatch: ThunkDispatch<RootState, undefined, RootAction>
) => {
  const filters = getCurrentTableFilters(state);

  const correctedFilters = tryCorrectingFiltersBasedOnApiError(filters, error);

  if (isNil(correctedFilters)) {
    errorToast();
    return;
  }

  dispatch(setFilters(TablesType.ObjectRecords, correctedFilters));
  dispatch(setApplyFilter(true, { sendToApi: true }));
};
