import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ThunkAction } from 'redux-thunk';
import { getPermissions } from 'store/actions/permissionsActions';
import { RootState, RootAction } from 'store/reducers';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_LIST } from 'utils/endpoints';
import DataFetchType from 'utils/Enums/DataFetchType';
import { createTableUrl } from 'utils/functions/createTableUrl';
import errorToast from 'utils/functions/errorToast';
import {
  PreferencesTypes,
  PreferencesDataState,
} from 'utils/types/api/preferences.types';
import {
  setFetchingObjectRecords,
  setObjectRecords,
  appendObjectRecords,
} from '../objectRecordsActions';
import { checkErrorForFilterCorrections } from './utils/checkErrorForFilterCorrections';

export const getObjectRecords = (
  queryParams?: string,
  fetchType:
    | DataFetchType.Append
    | DataFetchType.Overwrite = DataFetchType.Overwrite
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { selectedClassId } =
    state?.preferences?.data[PreferencesTypes.GeneralPreferences] ||
    ({} as PreferencesDataState);

  if (selectedClassId === undefined) {
    return;
  }

  dispatch(setFetchingObjectRecords(true));

  try {
    const { status, data } = await apiCall.get(
      createTableUrl(
        OBJECT_RECORD_LIST,
        `${
          !!queryParams ? queryParams + '&' : ''
        }object_class=${selectedClassId}`
      )
    );

    const {
      status: totalCountStatus,
      data: totalCountData,
    } = await apiCall.get(
      createTableUrl(
        OBJECT_RECORD_LIST,
        `object_class=${selectedClassId}&limit=0`
      )
    );

    if (status === StatusCodes.OK && totalCountStatus === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setObjectRecords(
            data.results,
            totalCountData.filtered_count,
            data.filtered_count
          )
        );
      } else {
        dispatch(
          appendObjectRecords(
            data.results,
            totalCountData.filtered_count,
            data.filtered_count
          )
        );
      }
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      errorToast();
      return;
    }

    switch (error.response?.status) {
      case StatusCodes.FORBIDDEN:
        dispatch(getPermissions());
        return;
      case StatusCodes.BAD_REQUEST:
        checkErrorForFilterCorrections(error, state, dispatch);
        return;
      default:
        errorToast();
        return;
    }
  } finally {
    dispatch(setFetchingObjectRecords(false));
  }
};
