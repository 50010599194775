import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_OBJECT_RECORDS,
  RESET_OBJECT_RECORDS,
  SET_OBJECT_RECORDS_FETCHING,
  SET_OBJECT_RECORDS,
  SET_OBJECT_RECORDS_COLUMNS,
  RESET_OBJECT_RECORDS_COLUMNS,
  SET_OBJECT_RECORDS_SELECTED_ROW,
  SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK,
  SET_OBJECT_RECORDS_SELECTED_COLUMNS,
  SET_OBJECT_RECORDS_RESTRICTIONS,
  SET_OBJECT_RECORDS_ERROR,
} from 'store/constants/objects.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_DETAILS_FIELDS,
  OBJECT_CLASS_FIELDS_AUTOCOMPLETE,
  OBJECT_RECORD_LIST,
} from 'utils/endpoints';
import { OptionsResponse } from 'utils/types';
import { ObjectRecord } from 'utils/types/api/objectRecords.types';
import {
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
  Restrictions,
} from 'utils/types/api/table.types';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import { generatePath } from 'react-router-dom';
import { CustomTableGroupKeys } from 'contexts/types';
import { FIELD_PREFIX } from 'utils/consts';
import axios from 'axios';
import { ClassField } from 'components/AddColumn/types';
import { ResponseError } from '../../../utils/types/errorResponse';
import { Logger, LogLevel } from 'utils/logger';

export type SetObjectsAction = SetTableAction<
  Pick<ActionObject<ObjectRecord>, 'list' | 'total' | 'filtered'>,
  typeof SET_OBJECT_RECORDS
>;

export type AppendObjectsAction = SetTableAction<
  Pick<ActionObject<ObjectRecord>, 'list' | 'total' | 'filtered'>,
  typeof APPEND_OBJECT_RECORDS
>;

export type SetObjectsFetchingAction = SetTableAction<
  Pick<ActionObject<ObjectRecord>, 'fetching'>,
  typeof SET_OBJECT_RECORDS_FETCHING
>;

export interface SetObjectsSelectedColumnsAction
  extends Action<typeof SET_OBJECT_RECORDS_SELECTED_COLUMNS> {
  payload: {
    selectedClassId: string;
    selectedColumns: ClassField[];
    initialColumns?: ClassField[];
  };
}

export type SetObjectsColumnsAction = SetTableAction<
  Pick<ActionObject<ObjectRecord>, 'payload'>,
  typeof SET_OBJECT_RECORDS_COLUMNS
>;

export type ResetObjectRecordsColumnsAction = Action<
  typeof RESET_OBJECT_RECORDS_COLUMNS
>;

export type ResetObjectsAction = Action<typeof RESET_OBJECT_RECORDS>;

export type SetSelectedRowAction = SetTableAction<
  Pick<ActionObject<ObjectRecord>, 'selectedRow'>,
  typeof SET_OBJECT_RECORDS_SELECTED_ROW
>;

export interface SetRemoveOwnerCallback
  extends Action<typeof SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK> {
  payload: () => void;
}

export type SetObjectRecordsRestrictionsAction = SetTableAction<
  { payload: Restrictions },
  typeof SET_OBJECT_RECORDS_RESTRICTIONS
>;

export interface SetObjectRecordErrorAction
  extends Action<typeof SET_OBJECT_RECORDS_ERROR> {
  payload: ResponseError | undefined;
}

export type ObjectsAction =
  | SetObjectsAction
  | SetObjectsFetchingAction
  | AppendObjectsAction
  | ResetObjectsAction
  | SetObjectsColumnsAction
  | ResetObjectRecordsColumnsAction
  | SetSelectedRowAction
  | SetRemoveOwnerCallback
  | SetObjectsSelectedColumnsAction
  | SetObjectRecordsRestrictionsAction
  | SetObjectRecordErrorAction;

export const setObjectRecords = (
  list: ObjectRecord[],
  total: number,
  filtered: number
): SetObjectsAction => {
  return {
    type: SET_OBJECT_RECORDS,
    list,
    total,
    filtered,
  };
};

export const appendObjectRecords = (
  list: ObjectRecord[],
  total: number,
  filtered: number
): AppendObjectsAction => {
  return {
    type: APPEND_OBJECT_RECORDS,
    list,
    total,
    filtered,
  };
};

export const setFetchingObjectRecords = (
  fetching: boolean
): SetObjectsFetchingAction => {
  return {
    type: SET_OBJECT_RECORDS_FETCHING,
    fetching,
  };
};

export const resetObjectRecords = (): ResetObjectsAction => {
  return {
    type: RESET_OBJECT_RECORDS,
  };
};

export const resetObjectRecordsColumns = (): ResetObjectRecordsColumnsAction => {
  return {
    type: RESET_OBJECT_RECORDS_COLUMNS,
  };
};

export const setObjectRecordsRestrictions = (
  restrictions: Restrictions
): SetObjectRecordsRestrictionsAction => ({
  type: SET_OBJECT_RECORDS_RESTRICTIONS,
  payload: restrictions,
});

export const setObjectRecordError = (
  payload: ResponseError
): SetObjectRecordErrorAction => ({
  type: SET_OBJECT_RECORDS_ERROR,
  payload,
});

export const setObjectRecordsColumns = (
  columns: ColumnsMetadata[]
): SetObjectsColumnsAction => {
  return {
    type: SET_OBJECT_RECORDS_COLUMNS,
    payload: columns,
  };
};

export const setObjectRecordsSelectedColumns = (payload: {
  selectedClassId: string;
  selectedColumns: ClassField[];
  initialColumns?: ClassField[];
}): SetObjectsSelectedColumnsAction => {
  return {
    type: SET_OBJECT_RECORDS_SELECTED_COLUMNS,
    payload: payload,
  };
};

export const getObjectRecordsColumnConfiguration = (
  preferencesId?: string | number
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  try {
    const recordsOptionsApiCall = [
      apiCall.options<OptionsResponse>(OBJECT_RECORD_LIST),
    ];

    const preferences = getState().preferences.data[
      PreferencesTypes.TableLayoutPreferences
    ];

    const [
      recordOptionsResponse,
      fieldsDetailsResponse,
      predicatesResponse,
    ] = await Promise.all(
      preferencesId
        ? [
            ...recordsOptionsApiCall,
            apiCall.get(
              generatePath(`${OBJECT_CLASS_DETAILS_FIELDS}`, {
                id: preferencesId,
              }),
              {
                params: {
                  [`id__in`]: (
                    preferences.selectedColumns?.[preferencesId] ?? ['0']
                  )?.join(','),
                },
              }
            ),

            apiCall.get(
              generatePath(OBJECT_CLASS_FIELDS_AUTOCOMPLETE, {
                id: preferencesId,
              })
            ),
          ]
        : recordsOptionsApiCall
    );

    const predicates = Object.fromEntries(
      (predicatesResponse.data.results ?? [])?.map(
        ({
          value,
          values,
          predicates,
        }: {
          value: string;
          values: {
            value: string;
            text: string;
          }[];
          predicates: string[] | null;
        }) => [value, { values, predicates }]
      )
    );

    const {
      status,
      data: {
        list: { columns: recordColumns },
        restrictions,
      },
    } = recordOptionsResponse;

    const { status: fieldsStatus, data } = fieldsDetailsResponse || {};

    if (restrictions) dispatch(setObjectRecordsRestrictions(restrictions));

    if (preferencesId) {
      const cols = data.results.map((r: ClassField) => ({
        ...r,
        alias: `${FIELD_PREFIX}${r.alias}`,
      }));

      dispatch(
        setObjectRecordsSelectedColumns({
          selectedClassId: preferencesId.toString(),
          selectedColumns: cols,
          initialColumns: cols,
        })
      );
    }

    const resultsWithoutIdentifierColumn = data.results.filter(
      ({ is_identifier }: { is_identifier: boolean }) => !is_identifier
    );

    const fieldsColumns = (resultsWithoutIdentifierColumn || [])?.map(
      ({
        alias,
        label,
        type,
        order,
      }: {
        alias: string;
        label: string;
        type: string;
        order: number;
      }) => ({
        alias: `${FIELD_PREFIX}${alias}`,
        label,
        type,
        order,
        predicates: predicates[`${FIELD_PREFIX}${alias}`]?.predicates ?? [],
        values: predicates[`${FIELD_PREFIX}${alias}`]?.values ?? [],
        sort_ok: false,
        groupKey: CustomTableGroupKeys.ObjectClassFields,
      })
    );

    const columns = [...recordColumns, ...fieldsColumns];

    if (isStatusOk(preferencesId, status, fieldsStatus)) {
      dispatch(setObjectRecordsColumns(columns));
    }
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      Logger.log(
        'Unhandled type of error happened in getObjectRecordsColumnConfiguration',
        LogLevel.Error,
        error
      );
      return;
    }

    dispatch(setObjectRecordError(error.response));
  }
};

export const isStatusOk = (
  preferencesId: string | number | undefined,
  status: number,
  fieldsStatus: number
) => {
  return (
    (!preferencesId && status === StatusCodes.OK) ||
    (preferencesId &&
      status === StatusCodes.OK &&
      fieldsStatus === StatusCodes.OK)
  );
};

export const setObjectRecordsSelectedRow = (
  data: string | undefined
): SetSelectedRowAction => ({
  type: SET_OBJECT_RECORDS_SELECTED_ROW,
  selectedRow: data,
});

export const setRemoveOwnerCallback = (
  payload: () => void
): SetRemoveOwnerCallback => {
  return {
    type: SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK,
    payload,
  };
};
